






import Vue from 'vue'
import SignUp from '../../components/auth/SignUp.vue'
export default Vue.extend({
  components: {
      SignUp
  },
  metaInfo(){
      return  {
      title: "Sign Up - Our Nation Express",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "Welome to Our Nation Express, the best courier service in Jamaica. We serve over 200 clients weekly, providing delivery services to Montego Bay and Kingston. Sign up now to enjoy our cheap rates!"
        }
      ]
    };
    }
    
})
